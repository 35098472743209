<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Date Range :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div class="finance-report">
          <span class="wrapper-icon-report">
            <b-img alt="Rupiah" :src="require('../../../assets/img/Rupiah.png')" width="24px" height="24px;"></b-img>
          </span>
          <div class="px-3">
            <p class="mb-1">Initial Balance (Gr)</p>
            <h5><strong>{{ initBalance }}</strong></h5>
          </div>
        </div>
      </b-col>
      <b-col sm>
        <div class="finance-report">
          <span class="wrapper-icon-pending">
            <b-img alt="Wallet" :src="require('../../../assets/img/wallet.png')" width="24px" height="24px;"></b-img>
          </span>
          <div class="px-3">
            <p class="mb-1">Pending Transaction</p>
            <div class="d-flex box-pending">
              <h5><strong><span class="text-danger">{{ countJualEmas }}</span> Jual Emas</strong></h5>
              <h5><strong>|</strong></h5>
              <h5><strong><span class="text-danger">{{ countJualEmasPartner }}</span> Jual Emas Partner</strong></h5>
              <h5><strong>|</strong></h5>
              <h5><strong><span class="text-danger">{{ countJamimas }}</span> Jamimas</strong></h5>
            </div>
          </div>
        </div>
        <span class="text-muted">Complete pending transactions before proceeding.</span>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">
        <template slot="no-slot" slot-scope="prop">
          <span>{{ prop.rowIndex + 1 }}</span>
        </template>
        <template slot="date-slot" slot-scope="prop">
          <span>{{ prop.rowData.date }}</span>
        </template>
        <template slot="internal-slot" slot-scope="prop">
          <span>{{ prop.rowData.internal }}</span>
        </template>
        <template slot="partnership-slot" slot-scope="prop">
          <span>{{ prop.rowData.partnership }}</span>
        </template>
        <template slot="debet-slot" slot-scope="prop">
          <span>{{ prop.rowData.type === 'debit' ? prop.rowData.amount : '' }}</span>
        </template>
        <template slot="credit-slot" slot-scope="prop">
          <span>{{ prop.rowData.type === 'credit' ? prop.rowData.amount : '' }}</span>
        </template>
        <template slot="balance-slot" slot-scope="prop">
          <span>{{ prop.rowData.balance_gr }}</span>
        </template>
        <template slot="balance-rupiah" slot-scope="prop">
          <span>{{ prop.rowData.balance_rp | currency }}</span>
        </template>
      </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
  // import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';

  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        selected: [],
        user: [],
        isLoading: false,
        largeModal: false,
        filterText: '',
        message: '',
        initBalance: '',
        countJualEmas: 0,
        countJualEmasPartner: 0,
        countJamimas: 0,
        dateDetail: {
          start_from: '',
          end_from: ''
        },
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `daily-report`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'date-slot',
            sortField: 'date',
            direction: 'desc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'no-slot',
            sortField: 'no',
            title: 'No',
            width: '5%',
          },
          {
            name: 'date-slot',
            sortField: 'date',
            title: 'Date Range'
          },
          {
            name: 'internal-slot',
            sortField: 'internal',
            title: 'Internal'
          },
          {
            name: 'partnership-slot',
            sortField: 'partnership',
            title: 'Partnership'
          },
          {
            name: 'debet-slot',
            sortField: 'debet',
            title: 'Total Debet (Gr)'
          },
          {
            name: 'credit-slot',
            sortField: 'credit',
            title: 'Total Credit (Gr)'
          },
          {
            name: 'balance-slot',
            sortField: 'balance',
            title: 'Balance (Gr)'
          },
          {
            name: 'balance-rupiah',
            sortField: 'balance-rupiah',
            title: 'Balance (Rp)'
          },
        ]
      }
    },
    created(){
      this.$http.get(`daily-report/initial-balance`)
      .then((response) => {
        if(response.data){
          this.initBalance = response.data.initial_balance
        }
      })
      this.$http.get(`daily-report/pending-transactions`)
      .then((response) => {
        if(response.data){
          this.countJualEmas = response.data.jual_emas
          this.countJualEmasPartner = response.data.jual_emas_partner
          this.countJamimas = response.data.jamimas
        }
      })
    },
    methods: {
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      number(value){
        return value
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
    },
    mounted() {
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
<style>
/* Absolute Center Spinner */
  .box-pending{
    gap: 12px;
  }

  .finance-report {
    display: flex;
    align-items: center;
    justify-content: start;
    border: 1px solid #E4E7EA;
    border-radius: 4px;
    overflow: hidden;
  }

  .wrapper-icon-report {
    width: 56px;
    height: 56px;
    background: #4DBD74;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrapper-icon-pending {
    width: 56px;
    height: 56px;
    background: #F86C6B;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrapper-chart-report {
    padding: 24px 48px;
    border: 1px solid #E4E7EA;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .wrapper-chart-report p {
    font-size: 12px;
    font-weight: 500;
    color: #606060;
  }
  .loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  footer.modal-footer {
      display: none;
  }

/* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
